<template>
  <div>
    <div class="page-title">方片日志查看</div>
    <!-- 选项 -->
    <div style="float:left;width:100%;margin-top:1rem">
      <el-select
        v-model="form.proj"
        clearable
        style="width:160px"
      >
        <el-option v-for="item in ['Wafer ID','操作人','操作事项','操作内容']" :value="item">{{ item }}</el-option>
      </el-select>
      <el-input
        clearable
        placeholder="搜索关键字"
        v-model="form.keyword"
        style="width:290px"
        @keydown.native.enter="DoSearch"
      ></el-input>
      <el-button
        type="primary"
        plain
        @click="DoSearch"
      >搜索
      </el-button>
    </div>
    <!-- 表格 -->
    <div style="float:left;width:100%;margin-top:1rem">
      <pl-table
        use-virtual
        class="tb-edit"
        :data="tableData"
      >
        <pl-table-column label="数量" prop="Wafer_ID" width="90">
          <template slot-scope="scope">
            <span v-html="FormatWaferID(scope.row.Wafer_ID)"></span>
          </template>
        </pl-table-column>
        <pl-table-column label="颗粒数" prop="颗粒数" width="90"></pl-table-column>
        <pl-table-column label="操作人" prop="操作人" width="100"></pl-table-column>
        <pl-table-column label="操作事项" prop="操作事项" width="120"></pl-table-column>
        <pl-table-column label="操作时间" prop="操作时间" width="110"></pl-table-column>
        <pl-table-column label="操作内容" prop="操作内容">
          <template slot-scope="scope">
            <span v-html="scope.row.操作内容.replace(/\n/g,'<br>')"></span>
          </template>
        </pl-table-column>
        <pl-table-column label="备注" prop="备注" width="140"></pl-table-column>
      </pl-table>
    </div>
    <div style="float:left;width:100%;margin-top:1rem">
      <el-pagination
        :page-size="pageSize"
        :current-page.sync="page"
        :total="count"
        @current-change="DoSearch"
        layout="total, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {getSqLogs} from "../http/api";
import moment from "moment";

export default {
  name: "ProdSquareSkuLogs",
  data() {
    return {
      tableData: [],
      form: {},
      token: this.$cookies.get('token'),
      page: 1,
      pageSize: 50,
      count: 0,
    }
  },
  methods: {
    DoSearch() {
      this.form.page = this.page - 1
      getSqLogs({
        token: this.token,
        form: this.form,
      })
        .then(res => {
          let rs = res.data.rs
          rs.forEach((item, index) => {
            rs[index].操作时间 = moment(new Date(item.操作时间)).format('YYYY/MM/DD HH:mm:ss')
          })
          this.tableData = rs
          this.count = res.data.count
          this.pageSize = res.data.pageSize
        })
        .catch(err => console.log(err))
    }
  },
  computed: {
    FormatWaferID() {
      return WaferID => {
        try {
          WaferID = JSON.parse(WaferID)
          return WaferID.length
        } catch (e) {
          return 1
        }
      }
    }
  },
  activated() {
    this.DoSearch()
  }
}
</script>

<style scoped>

</style>
